<template>
  <content-header />
  <content>
    <div class="row justify-content-center"> 
      <div class="col-6">
        <div class="card">
          <div class="card-body text-center"> 
            <img src="/images/logo.png" alt="Logo" class="img-fluid"> 

      
          </div>
        </div>
      </div>
    </div>
  </content>
</template>

<script>
console.log(localStorage.getItem('user_access_token'))
export default {
  name: 'HomeView',
};
</script>

<style scoped>
.img-fluid{
    max-width: 42%;
}

</style>
