<template>
  <div>
    <SliderComponent
      v-if="selectedType === 'slider' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />

    <CardComponent
      v-if="selectedType === 'card' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />


     <SectionComponent
      v-if="selectedType === 'section' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />

    <ButtonLinkComponent
      v-if="selectedType === 'buttonLink' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />

    <ArticleComponent
      v-if="selectedType === 'article' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />
  </div>
</template>

<script>
import SliderComponent from "@/components/slider/SliderComponent.vue";
import CardComponent from "@/components/card/CardComponent.vue";
import SectionComponent from "@/components/block/BlockComponent.vue";
import ButtonLinkComponent from "@/components/buttonLink/ButtonLinkComponent.vue";
import ArticleComponent from "@/components/article/ArticleComponent.vue";

export default {
  components: {
    SliderComponent,
    CardComponent,
    SectionComponent,
    ButtonLinkComponent,
    ArticleComponent,
  },
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
    selectedType: {
      type: String,
      default: null,
    },
  },
  setup(_, { emit }) {
    const emitCloseItem = () => {
      emit("close-item");
    };

    return {
      emitCloseItem,
    };
  },
};
</script>
