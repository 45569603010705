import axios from '@/plugins/axios';

export const fetchAreasApi = async (params) => {
  try {
    const response = await axios.get('/areas', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching areas:', error);
    throw error;
  }
};

export const createAreaApi = async (areaData) => {
    const response = await axios.post('/areas', areaData);
    return response.data;
  };
  
  export const updateAreaApi = async (id, areaData) => {
    const response = await axios.put(`/areas/${id}`, areaData);
    return response.data;
  };

  export const deleteAreasApi = async (id) => {
    const response = await axios.delete(`/areas/${id}`);
    return response.data;
  };