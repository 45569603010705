<template>
  <div v-if="!isLoading" :class="{ 'concessionaire-form-container': isEditing }">
    <!-- Header solo visible en modo edición -->
    <div v-if="isEditing" class="header">
      <button @click="goBack" class="back-button">
        <i class="fas fa-arrow-left"></i>
      </button>
      <h1>Editar Concesionario</h1>
    </div>
    <div class="form-group custom-width">
      <label for="cuit">CUIT del Comerciante Habitualista</label>
      <input
        type="text"
        id="cuit"
        class="form-control"
        :value="habitualistaData.cuit"
        disabled
      />
    </div>

    <div class="separator-line"></div>

    <div class="form-row">
      <div class="form-group col">
        <label for="client_code">Código de Cliente</label>
        <input
          type="text"
          id="client_code"
          class="form-control"
          v-model="client_code"
        />
      </div>

      <div class="form-group col">
        <label for="member_number">Número de Socio</label>
        <input
          type="text"
          id="member_number"
          class="form-control"
          v-model="member_number"
        />
      </div>

      <div class="form-group col">
        <label for="business_name">Razón Social</label>
        <input
          type="text"
          id="business_name"
          class="form-control"
          placeholder="Ingresar Razón Social"
          v-model="business_name"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="province_id">Provincia</label>
        <div class="select-wrapper">
          <select
            id="province_id"
            class="form-control select-style"
            v-model="province_id"
          >
            <option value="">Seleccionar Provincia</option>
            <option
              v-for="province in provinces.data"
              :key="province.id"
              :value="province.id"
            >
              {{ province.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Localidad Selector -->
      <div class="form-group col">
        <label for="locality">Localidad</label>
        <div class="select-wrapper">
          <select
            id="locality"
            class="form-control select-style"
            v-model="locality_id"
          >
            <option value="">Seleccionar Localidad</option>
            <option
              v-for="localityOption in localities.data"
              :key="localityOption.id"
              :value="localityOption.id"
            >
              {{ localityOption.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Regional Selector -->
      <div class="form-group col">
        <label for="regional_id">Regional</label>
        <div class="select-wrapper">
          <select
            id="regional_id"
            class="form-control select-style"
            v-model="regional_id"
          >
            <option value="">Seleccionar Regional</option>
            <option
              v-for="regional in regionals.data"
              :key="regional.id"
              :value="regional.id"
            >
              {{ regional.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="business_name">Dirección</label>
        <input
          type="text"
          id="street_name"
          class="form-control"
          placeholder="Ingresar Dirección"
          v-model="street_name"
        />
      </div>

      <div class="form-group col">
        <label for="business_name">Numero</label>
        <input
          type="text"
          id="street_number"
          class="form-control"
          placeholder="Ingresar Numero"
          v-model="street_number"
        />
      </div>

      <div class="form-group col">
        <label for="business_name">Código Postal</label>
        <input
          type="text"
          id="postal_code"
          class="form-control"
          placeholder="Ingresar Código Postal"
          v-model="postal_code"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="phone">Teléfono</label>
        <input
          type="text"
          id="phone"
          class="form-control"
          placeholder="Ingresar Teléfono"
          v-model="phone"
        />
      </div>

      <div class="form-group col">
        <label for="email">Email</label>
        <input
          type="email"
          id="email"
          class="form-control"
          placeholder="Ingresar Correo Electrónico"
          v-model="email"
        />
      </div>
      <div class="form-group col">
        <label for="business_name">Website</label>
        <input
          type="text"
          id="website"
          class="form-control"
          placeholder="Ingresar website"
          v-model="website"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="vehicle_type">Tipo de Vehículo</label>
        <div class="select-wrapper">
          <select
            id="vehicle_type"
            class="form-control select-style"
            v-model="vehicle_type"
            @change="handleVehicleTypeChange"
          >
            <option value="">Seleccionar Tipo de Vehículo</option>
            <option
              v-for="type in vehicleTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group col">
        <label for="brand_id">Marca</label>
        <div class="select-wrapper">
          <select
            id="brand_id"
            class="form-control select-style"
            v-model="brand_id"
          >
            <option value="">Seleccionar Marca</option>
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div :class="buttonContainerClass">
      <button
        v-if="!isEditing"
        @click="prevStep"
        class="continue-button  small-button"
      >
        ANTERIOR
      </button>
      <button @click="submitForm" class="continue-button small-button">
        {{ isEditing ? "ACTUALIZAR CONCESIONARIO" : "CREAR CONCESIONARIO" }}
      </button>
    </div>
  </div>
 <div v-else class="loading-container">
    <div class="spinner"></div>
    <p>Cargando datos del formulario...</p>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import {
  createConcessionaire,
  updateConcessionaire,
} from "@/services/api/concessionaireService.js";
import {
  fetchProvinces,
  fetchAllLocalities,
  fetchProvinceRegionals,
  fetchProvinceLocalities,
} from "@/services/api/provinceService";
import { fetchRegionals } from "@/services/api/regionalService";
import { fetchBrandList, fetchAllBrands } from "@/services/api/brandService";
import { vehicleTypes } from "@/services/api/vehicleTypes";
import { useStore } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ConcessionaireFormStepTwo",
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const habitualistaData = computed(() => store.state.habitualistaData || {});
    const isLoading = ref(true);

    const client_code = ref(habitualistaData.value.client_code || "");
    const member_number = ref(habitualistaData.value.member_number || "");
    const business_name = ref(habitualistaData.value.business_name || "");
    const province_id = ref(habitualistaData.value.province_id || "");
    const locality_id = ref(habitualistaData.value.locality_id || "");
    const street_name = ref(habitualistaData.value.street_name || "");
    const street_number = ref(habitualistaData.value.street_number || "");
    const postal_code = ref(habitualistaData.value.postal_code || "");
    const phone = ref(habitualistaData.value.phone || "");
    const email = ref(habitualistaData.value.email || "");
    const brand_id = ref(habitualistaData.value.brand_id || "");
    const regional_id = ref(habitualistaData.value.regional_id || "");
    const website = ref(habitualistaData.value.website || "");

    const vehicle_type = ref("");

    const provinces = ref([]);
    const regionals = ref([]);
    const brands = ref([]);
    const localities = ref([]);

    const buttonContainerClass = computed(() => ({
      "button-container d-flex": true,
      "justify-content-end": props.isEditing,
    }));

    const vehicleTypesList = vehicleTypes;

    const goBack = () => {
      router.push({
        name: "AdministradorSubMenu",
        params: { subMenu: "concessionaires" },
      });
    };

  onMounted(async () => {
      try {
        provinces.value = await fetchProvinces();
        brands.value = await fetchAllBrands();

        if (province_id.value) {
          const [regionalsData, localitiesData] = await Promise.all([
            fetchProvinceRegionals(province_id.value),
            fetchProvinceLocalities(province_id.value),
          ]);
          regionals.value = regionalsData || [];
          localities.value = localitiesData || [];
        } else {
          regionals.value = await fetchRegionals();
          localities.value = await fetchAllLocalities();
        }
        
        isLoading.value = false;
      } catch (error) {
        console.error(
          "Error al cargar provincias, regionales o localidades:",
          error
        );
        isLoading.value = false;
      }
    });

    watch(province_id, async (newProvinceId) => {
      if (newProvinceId) {
        try {
          const [regionalsData, localitiesData] = await Promise.all([
            fetchProvinceRegionals(newProvinceId),
            fetchProvinceLocalities(newProvinceId),
          ]);
          regionals.value = regionalsData || [];
          localities.value = localitiesData || [];
        } catch (error) {
          console.error(
            "Error al obtener regionals o localities para la provincia:",
            error
          );
        }
      } else {
        regionals.value = await fetchRegionals();
        localities.value = await fetchAllLocalities();
      }
    });

    const handleVehicleTypeChange = async () => {
      if (vehicle_type.value) {
        try {
          brands.value = await fetchBrandList(vehicle_type.value);
        } catch (error) {
          console.error("Error al obtener lista de marcas:", error);
        }
      } else {
        brands.value = [];
      }
    };

    const buildFormData = () => ({
      cuit: habitualistaData.value.cuit,
      client_code: client_code.value,
      member_number: member_number.value,
      business_name: business_name.value,
      province_id: province_id.value,
      locality_id: locality_id.value,
      street_name: street_name.value,
      street_number: street_number.value,
      postal_code: postal_code.value,
      phone: phone.value,
      email: email.value,
      brand_id: brand_id.value,
      regional_id: regional_id.value,
      website: website.value,
    });

    const handleSuccessResponse = (message) => {
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: message,
      }).then(() => goBack());
    };

    const handleErrorResponse = (error) => {
      console.error("Error en la operación de concesionario:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema en la operación. Inténtelo de nuevo.",
      });
    };

    const performConcessionaireOperation = async (formData) => {
      if (props.isEditing) {
        await updateConcessionaire(habitualistaData.value.id, formData);
        handleSuccessResponse(
          "El concesionario ha sido actualizado exitosamente."
        );
      } else {
        await createConcessionaire(formData);
        handleSuccessResponse("El concesionario ha sido creado exitosamente.");
      }
    };

    const submitForm = async () => {
      const formData = buildFormData();
      try {
        await performConcessionaireOperation(formData);
      } catch (error) {
        handleErrorResponse(error);
      }
    };

    return {
      habitualistaData,
      client_code,
      member_number,
      business_name,
      province_id,
      locality_id,
      street_name,
      street_number,
      postal_code,
      phone,
      email,
      website,
      brand_id,
      regional_id,
      submitForm,
      provinces,
      regionals,
      brands,
      localities,
      vehicle_type,
      vehicleTypes: vehicleTypesList,
      handleVehicleTypeChange,
      goBack,
      buttonContainerClass,
      isLoading,
    };
  },
};
</script>


<style scoped>

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #333;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0056b3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.form-row .col {
  flex: 1;
}

.separator-line {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.select-wrapper {
  position: relative;
}

.select-style {
  appearance: none;
  background-color: #f0f0f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-right: 25px;
}

.select-wrapper::after {
  content: "▾";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 0.875rem;
  color: #555;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}



.continue-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 0.875rem;
}

.small-button {
  font-size: 0.875rem;
  padding: 8px 16px;
}

.concessionaire-form-container {
  margin: 0 auto;
  max-width: 1320px;
  padding: 24px;
  border-radius: 8px;
}

.header {
  display: flex !important;
  align-items: center !important;
  gap: 10px;
}

.back-button {
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
}

.back-button {
  margin-right: auto;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .concessionaire-form-container {
    padding: 16px;
    max-width: 100%;
  }

  .header {
    flex-direction: column;
    text-align: center;
  }
  .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .continue-button {
    width: 100%;
    max-width: 200px;
  }

  .form-group,
  .select-style {
    width: 100%;
  }

  .small-button {
    padding: 10px 16px;
  }
}

</style>