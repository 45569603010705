<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="/" class="brand-link">
      <img
        src="/images/logo.png"
        :alt="$store.state.app.name"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
      />
      <span class="brand-text font-weight-light">{{
        $store.state.app.name
      }}</span>
    </a>

    <div class="sidebar">
      <div class="user-panel mt-4 pb-4 mb-3 d-flex align-items-center">
        <div class="image" style="margin-right: 10px">
          <i
            class="fas fa-user-circle"
            style="
              color: #ffffff;
              font-size: 2.5rem;
              background: #4f4ffb;
              border-radius: 50%;
              padding: 10px;
            "
          ></i>
        </div>
        <div class="info">
          <a
            href="#"
            class="d-block"
            style="color: #ffffff; font-weight: bold; font-size: 1.1rem"
          >
            {{ userName }}
          </a>
          <span style="font-size: 0.9rem; color: #cccccc">Conectado</span>
        </div>
      </div>

      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
        >
          <nav-item-component
            v-for="(item, index) in sidebarMenuItemsService"
            :key="index"
            :icon="item.icon"
            :link="item.link"
            :subMenu="item.subMenu"
          >
            {{ item.label }}
          </nav-item-component>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import NavItemComponent from "./NavItemComponent.vue";
import { sidebarMenuItemsService } from "@/services/api/sidebarMenuItemsService.js";

export default {
  components: {
    NavItemComponent,
  },
  data() {
    return {
      sidebarMenuItemsService,
      userName: localStorage.getItem("user_name") || "Usuario",
    };
  },
};
</script>
