import { createStore } from 'vuex';

export default createStore({
  state: {
    count: 0,
    app: {
      name: 'Admin ACARA',
    },
    menuOpenState: {
    },
    priceCategory: null,
    habitualistaData: null,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    setPriceCategory(state, category) {
      state.priceCategory = category;
    },
    setMenuOpenState(state, { isOpen, menuKey }) {
      state.menuOpenState = { 
        ...state.menuOpenState,
        [menuKey]: isOpen 
      };
    },
    setMenuIndicesType(state, type) {
      state.menuIndices.type = type;
    },
    setHabitualistaData(state, data) { 
      state.habitualistaData = data;
    },
  },
  actions: {
    increment({ commit }) {
      commit('increment');
    },
    setPriceCategory({ commit }, category) {
      commit('setPriceCategory', category);
    },
    setMenuIndicesType({ commit }, type) {
      commit('setMenuIndicesType', type);
    },
    updateHabitualistaData({ commit }, data) { 
      commit('setHabitualistaData', data);
    },
  },
  modules: {},
});
