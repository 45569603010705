<template>
  <div class="modal fade" id="buttonLinkModal" tabindex="-1" aria-labelledby="buttonLinkModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="buttonLinkModalLabel">
            {{ isEditing ? "Editar Botón" : "Nuevo Botón" }}
          </h5>
          <button type="button" class="btn-close" @click="closeForm"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!-- Step 1 -->
            <div v-if="currentStep === 1">
              <div class="mb-3">
                <label for="type-select" class="form-label">Tipo de Elemento</label>
                <select v-model.number="form.type" class="form-select" id="type-select">
                  <option v-for="type in buttonLinkTypes" :key="type.value" :value="type.value">
                    {{ type.label }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="title" class="form-label">
                  Título
                  <i class="bi bi-question-circle cursor-pointer" @click="
                    showHelp(form.type === 3 ? 'title_banner' : 'title')
                    "></i>
                </label>
                <input type="text" v-model="form.title" class="form-control" id="title" @blur="onTitleUpdated" />
              </div>
              <transition name="fade">
                <div class="mb-3">
                  <label for="color" class="form-label">Color de fondo</label>
                  <input type="color" v-model="form.background_color"
                    class="form-control form-control-color custom-color-input" id="color" title="Elige tu color"
                    :disabled="foundBackgroundColor !== null" required />
                </div>
              </transition>
              <button type="button" class="btn btn-primary" @click="nextStep" :disabled="!isStep1Complete">
                Siguiente
              </button>
            </div>

            <!-- Step 2 -->
            <div v-if="currentStep === 2">
              <div class="mb-3">
                <label for="name" class="form-label">
                  Nombre
                  <i class="bi bi-question-circle cursor-pointer"
                    @click="showHelp(form.type === 3 ? 'name_banner' : 'name')"></i>
                </label>
                <input type="text" v-model="form.name" class="form-control" id="name" />
              </div>

              <div v-if="isLoading" class="text-center my-3">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Validando título...</span>
                </div>
              </div>

              <div class="mb-3">
                <label for="content" class="form-label">
                  Subtítulo
                  <i class="bi bi-question-circle cursor-pointer"
                    @click="showHelp(form.type === 3 ? 'subtitulo_banner' : 'subtitulo_other')"></i>
                </label>
                <input type="text" v-model="form.content" class="form-control" id="content"
                  :disabled="isContentLocked" />
              </div>

              <div class="mb-3 border p-3 rounded" v-if="form.type === 1 || form.type === 4 || form.type === 2">
                <label for="type" class="form-label fw-bold">Tipo de Enlace</label>
                <p class="form-text text-muted">
                  Selecciona la acción que deseas para el botón:
                  <strong>"Enlace"</strong> redireccionará a una URL, mientras que
                  <strong>"PDF"</strong> permitirá descargar un archivo.
                </p>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="select-addon">
                    <i class="bi bi-link-45deg"></i>
                  </span>
                  <select v-model="form.linkType" class="form-select shadow-sm" id="type"
                    aria-describedby="select-addon">
                    <option value="link">Enlace</option>
                    <option value="pdf">PDF</option>
                  </select>
                </div>

                <div class="mb-3" v-if="form.linkType === 'link'">
                  <label for="link" class="form-label">Enlace</label>
                  <input type="text" v-model="form.link" class="form-control shadow-sm" id="link" />
                  <p class="form-text text-muted">
                    Ingresa la URL completa para redireccionar al hacer clic en el
                    botón.
                  </p>
                </div>

                <FileUploadPreview v-if="form.linkType === 'pdf'" label="Subir PDF" id="file" accept="application/pdf"
                  :file="form.file" :preview="form.file ? form.file.name : ''" :required="!isEditing"
                  @file-change="updateFile('file', $event)" no-file-message="No se ha seleccionado ningún archivo" />
                <p v-if="form.linkType === 'pdf'" class="form-text text-muted">
                  Selecciona un archivo PDF para que el botón permita su descarga
                  al hacer clic.
                </p>
              </div>

              <FileUploadPreview accept="image/*" label="Imagen" id="image" :file="form.image"
                :preview="form.imagePreview" :required="!isEditing" @file-change="updateFile('image', $event)"
                no-file-message="No se ha seleccionado ninguna imagen" />

              <div class="mb-3">
                <label for="order" class="form-label">Orden</label>
                <input type="number" v-model="form.order" class="form-control" id="order" required />
              </div>
              <button type="button" class="btn btn-secondary" @click="prevStep">
                Anterior
              </button>
              <button type="submit" class="btn btn-primary" :disabled="!isFormValid">
                {{ isEditing ? "Actualizar" : "Guardar" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <HelpModal v-if="helpField" :field="helpField" @close="helpField = null" />
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from "vue";
import { Modal } from "bootstrap";
import {
  createButtonLink,
  updateButtonLink,
  fetchButtonLinksByTitle,
} from "@/services/api/buttonLinkService.js";
import FileUploadPreview from "@/components/shared/FileUploadPreview.vue";
import userConfirmation from "@/utils/userConfirmation.js";
import HelpModal from "@/components/shared/HelpModal.vue";
import { buildFormData } from "@/utils/formDataBuilder.js";
import { showLoadingAlert, closeLoadingAlert } from "@/utils/loadingAlert.js";

const BUTTON_LINK_MODAL_ID = "buttonLinkModal";

export default {
  components: {
    FileUploadPreview,
    HelpModal,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      default: null,
    },
    buttonLinkTypes: {
      type: Array,
      required: true,
    },
    foundBackgroundColor: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const helpField = ref(null);
    const isEditing = ref(false);
    const currentStep = ref(1);
    const isContentLocked = ref(false);
    const form = ref({
      name: "",
      title: "",
      content: "",
      link: "",
      file: null,
      image: null,
      order: "",
      view_id: props.view.id,
      imagePreview: "",
      linkType: "link",
      background_color: "#FEFBF9",
      type: 1, // Valor inicial para 'type', 1 sin botón, 2 con botón, 3 banner
    });

    const isStep1Complete = computed(() => {
      return form.value.type !== null && (form.value.type === 1 || form.value.title.trim() !== "");
    });


    const isFormValid = computed(() => {
      const content = form.value.content ? form.value.content.trim() : "";
      const link = form.value.link ? form.value.link.trim() : "";
      const file = form.value.file
        ? typeof form.value.file === "string"
          ? form.value.file.trim()
          : form.value.file.name || ""
        : "";

      const imageIsValid = form.value.image || form.value.imagePreview;

      const isContentRequired = form.value.type === 3 ? content !== "" : true;

      return (
        // (form.value.type !== 3 || title !== "") &&
        form.value.order !== "" &&
        isContentRequired &&
        (form.value.type === 3 || link !== "" || file !== "") &&
        imageIsValid
      );
    });

    const searchButtonLinkByTitle = async ({ title, view_id, type }) => {
      try {
        const buttonLinks = await fetchButtonLinksByTitle(view_id, title, type);
        if (Array.isArray(buttonLinks) && buttonLinks.length > 0) {
          const firstButtonLink = buttonLinks[0];
          if (firstButtonLink && firstButtonLink.content) {
            form.value.content = firstButtonLink.content;
            isContentLocked.value = true;
          } else {
            form.value.content = "";
            isContentLocked.value = false;
          }
        } else {
          form.value.content = "";
          isContentLocked.value = false;
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          isContentLocked.value = false;
          form.value.content = "";
        } else {
          console.error("Error inesperado:", error);
        }
      }
    };

    const nextStep = () => {
      currentStep.value = 2;
    };

    const prevStep = () => {
      currentStep.value = 1;
    };

    const showHelp = (field) => {
      helpField.value = field;
    };

    watch(
      () => ({ type: form.value.type, title: form.value.title }),
      ({ type, title }) => {
        if (type === 3 || type === 4) {
          form.value.background_color = "#101b5a";
        }
        emit("fetchButtonColor", type, title);
      }
    );

    watch(
      () => props.foundBackgroundColor,
      (newColor) => {
        if (newColor) {
          console.log("form.value.title", form.value.title);
          form.value.background_color = newColor;
        }
      }
    );

    watch(
      () => props.header,
      async (newHeader) => {
        if (newHeader) {
          isEditing.value = true;
          form.value = {
            name: newHeader.name,
            title:
              typeof newHeader.title === "string" &&
                newHeader.title?.toLowerCase() === "no title"
                ? ""
                : newHeader.title || "",
            content: newHeader.content,
            link: newHeader.link,
            image: null,
            file: newHeader.file || null,
            order: newHeader.order,
            view_id: newHeader.view.id,
            imagePreview: newHeader.image,
            linkType: newHeader.link ? "link" : newHeader.file ? "pdf" : "link",
            background_color: newHeader.background_color || "#FEFBF9",
            type: newHeader.type || 1, // 1 sin botón, 2 con botón, 3 banner
          };

          if (form.value.type === 3 || form.value.type === 4) {
            await searchButtonLinkByTitle({
              title: form.value.title,
              view_id: form.value.view_id,
              type: form.value.type,
            });
          }
        } else {
          isEditing.value = false;
          form.value.imagePreview = "";
          form.value.linkType = "link";
          form.value.type = 1;
        }
      },
      { immediate: true }
    );

    const onTitleUpdated = async () => {
      if (form.value.type === 3 || form.value.type === 4) {
        isLoading.value = true;
        try {
          await searchButtonLinkByTitle({
            title: form.value.title,
            view_id: form.value.view_id,
            type: form.value.type,
          });
        } finally {
          isLoading.value = false;
        }
      }
    };

    const updateFile = (type, file) => {
      form.value[type] = file;
    };

    const submitForm = async () => {
      if (form.value.type !== 3) {
        if (form.value.linkType === "link") {
          form.value.file = null;
        } else if (form.value.linkType === "pdf") {
          form.value.link = null;
        }
      }

      const formData = buildFormData(form.value, form.value.linkType);

      const editOrCreate = isEditing.value ? "editar" : "crear";
      const confirmed = await userConfirmation(
        `¿Está seguro de ${editOrCreate} el button?`
      );
      if (!confirmed) return;
      showLoadingAlert();
      try {
        if (isEditing.value) {
          await updateButtonLink(props.header.id, formData);
          emit("success", "Button actualizado exitosamente.");
        } else {
          await createButtonLink(formData);
          emit("success", "Button creado exitosamente.");
        }

        emit("buttonLink-created");
        closeForm();
      } catch (error) {
        emit(
          "error",
          error.response?.data?.message || "Error al procesar la solicitud."
        );
      } finally {
        closeLoadingAlert();
      }
    };

    const closeForm = () => {
      emit("close");
      const modalElement = document.getElementById(BUTTON_LINK_MODAL_ID);
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance.hide();
    };

    onMounted(() => {
      const modalElement = document.getElementById(BUTTON_LINK_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      form,
      isEditing,
      updateFile,
      submitForm,
      closeForm,
      nextStep,
      prevStep,
      currentStep,
      showHelp,
      onTitleUpdated,
      helpField,
      isContentLocked,
      isLoading,
      isFormValid,
      isStep1Complete,
    };
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.form-label {
  font-weight: 600;
}

.form-check-input {
  cursor: pointer;
}

.btn {
  margin-top: 1rem;
}

.input-group-text {
  background-color: #e9ecef;
  border-color: #ced4da;
}

input,
select,
textarea {
  margin-top: 0.5rem;
}

.border {
  border: 1px solid #ced4da;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-primary:hover,
.btn-secondary:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .modal-xl {
    width: 100%;
    max-width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.custom-color-input {
  width: 100%;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-size: 16px;
}

/* Estilos para la transición */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>
