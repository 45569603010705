<template>
  <div
    class="modal fade"
    id="ViewModal"
    tabindex="-1"
    aria-labelledby="viewModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="viewModalLabel">
            {{ formData.id ? "Editar Vista" : "Nueva Vista" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="name" class="form-label">Nombre:</label>
              <input
                type="text"
                id="name"
                v-model="formData.name"
                class="form-control"
                required
              />
            </div>
            <button
              type="submit"
              class="btn btn-primary w-100"
              :disabled="loading"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-else>{{ formData.id ? "Actualizar" : "Guardar" }}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { Modal } from "bootstrap";
import { createView, updateView } from "@/services/api/viewService";
import userConfirmation from "@/utils/userConfirmation.js";

export default {
  props: {
    viewToEdit: Object,
    currentUrl: String,
    isFormOpen: Boolean,
  },
  setup(props, { emit }) {
    const formData = ref({
      id: null,
      name: "",
      url: "",
    });

    const loading = ref(false);
    let modalInstance = null;

    const resetFormData = () => {
      formData.value = {
        id: null,
        name: "",
        url: "",
      };
    };

    watch(
      () => props.viewToEdit,
      (newView) => {
        if (newView) {
          formData.value = { ...newView };
        } else {
          resetFormData();
        }
      },
      { immediate: true }
    );

    watch(
      () => props.isFormOpen,
      (newValue) => {
        if (newValue) {
          showModal();
        } else {
          resetFormData();
        }
      }
    );

    const generateSlug = (name) => {
      return name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "");
    };

    const submitForm = async () => {
      loading.value = true;
      try {
        formData.value.url = generateSlug(formData.value.name);

        const editOrCreate = formData.value.id ? "editar" : "crear";
        const confirmed = await userConfirmation(
          `¿Está seguro de ${editOrCreate} la vista?`
        );
        if (!confirmed) return;

        if (formData.value.id) {
          try {
            await updateView(props.currentUrl, {
              name: formData.value.name,
              url: formData.value.url,
            });
            emit(
              "view-updated-success",
              "La vista ha sido actualizada exitosamente."
            );
          } catch (error) {
            console.error("Error in updateView:", error);
            emit(
              "error",
              error.message || "Hubo un problema al actualizar la vista."
            );
            return; 
          }
        } else {
          await createView({
            name: formData.value.name,
            url: formData.value.url,
          });
          emit(
            "view-created-success",
            "La nueva vista ha sido creada exitosamente."
          );
        }
      } catch (error) {
        console.error("Error saving view:", error);
        emit("error", error.message || "Hubo un problema al guardar la vista.");
      } finally {
        loading.value = false;
        closeModal();
      }
    };

    const showModal = () => {
      const modalElement = document.getElementById("ViewModal");
      if (modalElement) {
        modalInstance = new Modal(modalElement);
        modalInstance.show();
      }
    };

    const closeModal = () => {
      const modalElement = document.getElementById("ViewModal");
      if (modalElement) {
        const modal =
          Modal.getInstance(modalElement) || new Modal(modalElement);
        modal.hide();
        resetFormData();
        emit("close");
      } else {
        console.error("Modal element not found.");
      }
    };

    return {
      formData,
      loading,
      submitForm,
      showModal,
      closeModal,
    };
  },
};
</script>

<style scoped>
.modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}
.modal-title {
  font-size: 1.25rem;
  font-weight: 500;
}
.modal-body {
  padding: 1.5rem;
}
.form-label {
  font-weight: 500;
}
.form-control,
.form-select {
  border-radius: 0.25rem;
}
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}
.spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
</style>
