<template>
  <div class="modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Detalles de Precios</h5>
          <button type="button" class="close" @click="$emit('close')">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="main-data">
            <div class="main-data-item">
              <strong>Tipo:</strong> {{ typeLabel }}
            </div>
            <div class="main-data-item">
              <strong>Marca:</strong> {{ modalData.brand }}
            </div>
            <div class="main-data-item">
              <strong>Modelo:</strong> {{ modalData.model }}
            </div>
            <div class="main-data-item">
              <strong>Versión:</strong> {{ modalData.version }}
            </div>
            <div class="main-data-item">
              <strong>Moneda:</strong> {{ modalData.currency }}
            </div>
            <div class="main-data-item">
              <strong>0 Km:</strong> {{ modalData["0km"] }}
            </div>
          </div>

          <div class="year-columns">
            <div v-for="({ year, price }) in allPrices" :key="year" class="year-item">
              <strong>{{ year }}:</strong> {{ year === "0Km" ? price : formatPrice(price) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

export default {
  emits: ['close'],
  props: {
    modalData: Object
  },
  setup(props) {
    const types = ref([
      { value: "1", label: "Autos" },
      { value: "2", label: "Motos" },
      { value: "3", label: "Camiones" },
      { value: "4", label: "Maquinaria Agricola" },
      
    ]);

    const typeLabel = computed(() => {
      const type = types.value.find(t => t.value === String(props.modalData.type));
      return type ? type.label : 'Desconocido';
    });

    const allPrices = computed(() => {
      return Object.entries(props.modalData.prices).map(([year, price]) => ({
        year,
        price
      }));
    });

    const formatPrice = (price) => {
      return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(price);
    };

    return {
      typeLabel,
      allPrices,
      formatPrice
    };
  }
};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1050;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  position: relative;
  margin: 2rem auto;
  pointer-events: none;
}

.modal-lg {
  max-width: 70%;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff; 
  border: none;
  border-radius: 0.5rem;
  pointer-events: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: #f8f9fa; 
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem 1.5rem; 
  overflow: auto;
}

.main-data {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 1rem;
  margin-bottom: 2rem;
  background-color: #f0f0f0; 
  padding: 1rem; 
  border-radius: 0.25rem; 
}

.main-data-item {
  display: flex;
  align-items: flex-start; 
  margin-bottom: 0.5rem; 
}

.main-data-item strong {
  min-width: 6em; 
  display: inline-block;
  text-align: left;
  margin-right: 1em;
  color: #495057;
}

.year-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  border-top: 1px solid #dee2e6;
  padding-top: 1rem;
  justify-content: center;
  padding: 0 2rem;
}

.year-item {
  padding: 0.75rem;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 90%;
  }

  .year-columns {
    grid-template-columns: 1fr;
  }
}

.year-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.year-item strong {
  min-width: 6em;
  margin-right: 1em;
}
</style>
