<template>
  <div class="container p-5">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="mb-0" style="font-size: 2rem">Vistas</h1>
      <div class="d-flex align-items-center mt-6 mt-md-0">
        <button @click="openForm()" class="btn btn-primary">Nueva Vista</button>
      </div>
    </div>

    <SearchInput v-model="searchTerm" />

    <div v-if="loading" class="text-center">Cargando...</div>
    <div v-else>
      <ViewsTable
        :views="filteredViews"
        :table-headers="tableHeaders"
        :table-columns="tableColumns"
        @edit-view="openForm"
        @delete-view="deleteView"
        @show-item="showItem"
      />

      <Pagination
        :current-page="currentPage"
        :last-page="lastPage"
        :has-more-pages="hasMorePages"
        @fetch-views="fetchViews"
      />
    </div>

    <CreateViewModal
      :isFormOpen="isFormOpen"
      @close="closeForm"
      @view-created="fetchViews"
      @view-created-success="handleSuccess"
      @view-updated-success="handleSuccess"
      @error="handleError"
      :viewToEdit="viewToEdit"
      :currentUrl="viewToEdit ? viewToEdit.url : ''"
    />

    <ViewModalsComponent
      :selected-item="selectedItem"
      :selected-type="selectedType"
      @close-item="closeItem"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { ref, computed, onMounted } from "vue";
import CreateViewModal from "./CreateViewModal.vue";
import ViewModalsComponent from "@/components/vista/ViewModalsComponent.vue";
import SearchInput from "@/components/data-display/SearchInput.vue";
import ViewsTable from "@/views/vistas/ViewsTable.vue";
import Pagination from "@/components/data-display/DataPagination.vue";
import {
  fetchViews as fetchViewsApi,
  deleteView as deleteViewApi,
} from "@/services/api/viewService.js";
import userConfirmation from "@/utils/userConfirmation.js";

export default {
  components: {
    CreateViewModal,
    ViewModalsComponent,
    SearchInput,
    ViewsTable,
    Pagination,
  },
  setup() {
    const views = ref([]);
    const searchTerm = ref("");
    const isFormOpen = ref(false);
    const loading = ref(false);
    const currentPage = ref(1);
    const lastPage = ref(1); 
    const hasMorePages = computed(() => currentPage.value < lastPage.value);
    const viewToEdit = ref(null);

    const selectedItem = ref(null);
    const selectedType = ref(null);

    const tableHeaders = ref(["Nombre", "Creado", "Actualizado", "Opciones"]);
    const tableColumns = ref([
      { key: "name", isDate: false },
      { key: "created_at", isDate: true },
      { key: "updated_at", isDate: true },
    ]);

    const filteredViews = computed(() => {
      return searchTerm.value
        ? views.value.filter(
            (view) =>
              view.name
                .toLowerCase()
                .includes(searchTerm.value.toLowerCase()) ||
              view.url.toLowerCase().includes(searchTerm.value.toLowerCase())
          )
        : views.value;
    });

    const fetchViews = async (page = 1) => {
      loading.value = true;
      try {
        const data = await fetchViewsApi(page);
        views.value = data.data;
        currentPage.value = data.current_page || 1;
        lastPage.value = data.last_page || 1;
      } catch (error) {
        handleError(error.message);
      } finally {
        loading.value = false;
      }
    };

    const showItem = (view, type) => {
      selectedItem.value = view;
      selectedType.value = type;
    };

    const closeItem = () => {
      selectedItem.value = null;
      selectedType.value = null;
    };

    const openForm = () => {
      // resetViewToEdit(view);
      // isFormOpen.value = true;
      Swal.fire({
        icon: "info",
        title: "En mantenimiento",
        text: "Esta funcionalidad está fuera de servicio por mantenimiento.",
        confirmButtonText: "Entendido",
      });
    };

/*     const resetViewToEdit = (view) => {
      viewToEdit.value = view ? { ...view } : null;
    }; */
    const closeForm = () => {
      isFormOpen.value = false;
    };


    const deleteView = async (url) => {
      const confirmed = await userConfirmation(
        `¿Está seguro de eliminar la vista seleccionada?`
      );
      if (!confirmed) return;

      try {
        await deleteViewApi(url);
        fetchViews(currentPage.value);
      } catch (error) {
        handleError(error.message);
      }
    };

    const handleError = (message) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: message,
        confirmButtonText: "Aceptar",
      });
    };

    const handleSuccess = (message) => {
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: message,
        confirmButtonText: "Aceptar",
      });
      fetchViews(currentPage.value);
    };

    onMounted(() => {
      fetchViews();
    });

    return {
      viewToEdit,
      views,
      searchTerm,
      isFormOpen,
      loading,
      currentPage,
      lastPage,
      hasMorePages,

      selectedItem,
      selectedType,

      filteredViews,
      fetchViews,

      showItem,
      closeItem,

      openForm,
      closeForm,
      deleteView,
      handleError,
      handleSuccess,
      tableHeaders,
      tableColumns,
    };
  },
};
</script>
<style scoped>
.container {
  max-width: 100% !important;
}
</style>
