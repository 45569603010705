<template>
  <div class="table-responsive" style="padding-bottom: 20px">
    <table
      ref="table"
      :id="tableId"
      class="table table-bordered table-striped table-responsive-stack"
      style="width: 100%; min-height: 400px"
    >
      <thead class="custom-thead">
        <tr>
          <th v-for="header in tableHeaders" :key="header">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="view in views" :key="view.id">
          <td v-for="column in tableColumns" :key="column.key">
            {{ formatColumn(view[column.key], column.isDate) }}
          </td>
          <td class="text-center" style="position: relative">
            <div class="btn-group btn-group-sm">
           <!--    <button
                @click="emitEditView(view)"
                class="btn btn-outline-primary"
              >
                <i class="fas fa-edit"></i> Editar
              </button> -->
              
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-dark"
                  @click.stop="toggleDropdown(view.id)"
                  :aria-expanded="dropdownVisible === view.id"
                >
                  <i class="fas fa-cog"></i>
                </button>

                <div
                  v-if="dropdownVisible === view.id"
                  class="dropdown-menu show"
                  style="z-index: 1000"
                >
                  <a
                    v-for="action in dropdownActions"
                    :key="action.label"
                    class="dropdown-item"
                    href="#"
                    @click.prevent="emitShowItem(view, action.type)"
                  >
                    {{ action.label }}
                  </a>
                </div>
              </div>
              <button
                @click="emitDeleteView(view.url)"
                class="btn btn-outline-danger"
              >
                <i class="fas fa-trash"></i> Eliminar
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { applyResponsiveStack } from "@/utils/responsiveTable";


export default {
  props: {
    views: {
      type: Array,
      required: true,
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dropdownVisible = ref(null);
    const tableId = "viewsTable";

    const toggleDropdown = (viewId) => {
      dropdownVisible.value = dropdownVisible.value === viewId ? null : viewId;
    };

    const closeDropdown = () => {
      dropdownVisible.value = null;
    };

    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const formatColumn = (value, isDate) =>
      isDate ? formatDate(value) : value;

    const emitEditView = (view) => emit("edit-view", view);
    const emitDeleteView = (url) => emit("delete-view", url);
    const emitShowItem = (view, type) => emit("show-item", view, type);

    const dropdownActions = [
      { label: "Carrousel", type: "slider" },
      { label: "Tarjeta", type: "card" },
      { label: "Bloques", type: "section" },
      { label: "Botonera", type: "buttonLink" },
      { label: "Articulo", type: "article" },
    ];

    const handleClickOutside = (event) => {
      if (!event.target.closest(`#${tableId}`)) {
        closeDropdown();
      }
    };

    onMounted(() => {
      applyResponsiveStack(tableId);
      document.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      dropdownVisible,
      toggleDropdown,
      formatColumn,
      tableId,
      emitEditView,
      emitDeleteView,
      emitShowItem,
      dropdownActions,
    };
  },
};
</script>

<style scoped>
.table-responsive-stack tr {
  display: flex;
  flex-direction: row;
}

.table-responsive-stack td,
.table-responsive-stack th {
  display: block;
  flex: 1 1 0;
  box-sizing: border-box;
}

.table-responsive-stack .table-responsive-stack-thead {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .table-responsive-stack tr {
    flex-direction: column;
    border-bottom: 3px solid #ccc;
    display: block;
  }
  .table-responsive-stack td {
    float: left\9;
    width: 100%;
  }
}

.btn-group-sm .btn {
  min-width: 80px;
}

@media (max-width: 768px) {
  .table {
    font-size: 14px;
  }
  .btn-group-sm .btn {
    min-width: 60px;
  }
  .btn-group-sm .dropdown-menu {
    font-size: 14px;
  }
}

.btn-group .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  z-index: 1000;
}

.btn-group .dropdown-menu.show {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .btn-group .dropdown-menu {
    left: -95px;
  }
}

.dropdown-item {
  color: #333;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
  color: #007bff;
}

.btn-group .btn-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.btn-group .btn-dark i {
  margin-right: 5px;
}

.btn-group .btn-dark:after {
  content: "▼";
  font-size: 12px;
  margin-left: 5px;
  color: #ffffff;
  transition: transform 0.3s ease;
}

.btn-group .btn-dark[aria-expanded="true"]:after {
  transform: rotate(180deg);
}

.btn-group .btn-dark[aria-expanded="true"] .fa-cog {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.table-responsive {
  position: relative;
  overflow: visible;
  padding-bottom: 20px;
}

.custom-thead {
  background-color: #f8f9fa; /* Color de fondo claro */
  color: #333; /* Color de texto oscuro */
}

.custom-thead th {
  font-weight: bold;
  padding: 12px;
  text-align: center;
  border-bottom: 2px solid #e0e0e0;
  font-size: 0.95rem;
  text-transform: uppercase;
}

.custom-thead th:first-child {
  border-top-left-radius: 8px; /* Bordes redondeados para las esquinas */
}

.custom-thead th:last-child {
  border-top-right-radius: 8px;
}

.custom-thead th {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
}

.table-responsive-stack th,
.table-responsive-stack td {
  border: none; /* Elimina los bordes de las celdas si no son necesarios */
}

.table-responsive-stack .table-responsive-stack-thead {
  font-weight: bold;
}

.custom-thead th:last-child {
  text-align: center;
}
</style>
