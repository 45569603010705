<template>
  <div class="modal fade" id="sliderFormModal" tabindex="-1" aria-labelledby="sliderFormModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sliderFormModalLabel">{{ isEditing ? 'Editar Slider' : 'Nuevo Slider' }}</h5>
          <button type="button" class="btn-close" @click="closeForm"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            
            <ToggleField
              label="Título"
              id="title"
              v-model="form.title"
              type="input"
            />

            <ToggleField
              label="Subtítulo"
              id="content"
              v-model="form.content"
              type="textarea"
            />

            <ToggleField
              label="Enlace"
              id="link"
              v-model="form.link"
              type="input"
            />

            <FileUploadPreview
              accept="image/*,video/webm"
              label="Imagen"
              id="image"
              :file="form.image"
              :preview="form.imagePreview"
              :required="!isEditing"
              @file-change="updateFile('image', $event)"
              no-file-message="No se ha seleccionado ninguna imagen"
            />

            <div class="mb-3">
              <label for="order" class="form-label">Orden</label>
              <input type="number" v-model="form.order" class="form-control" id="order" required>
            </div>
            <button type="submit" class="btn btn-primary">{{ isEditing ? 'Actualizar' : 'Guardar' }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { Modal } from 'bootstrap';
import { createSlider, updateSlider } from '@/services/api/sliderService.js';
import FileUploadPreview from '@/components/shared/FileUploadPreview.vue';
import ToggleField from '@/components/shared/ToggleField.vue';
import userConfirmation from "@/utils/userConfirmation.js";
import { showLoadingAlert, closeLoadingAlert } from "@/utils/loadingAlert.js";

const SLIDER_FORM_MODAL_ID = 'sliderFormModal';

export default {
  components: {
    FileUploadPreview,
    ToggleField,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false);
    const form = ref({
      title: '',
      content: '',
      link: '',
      image: null,
      order: '',
      view_id: props.view.id,
      imagePreview: '',
    });

    watch(() => props.header, (newHeader) => {
      if (newHeader) {
        isEditing.value = true;
        form.value = {
          title: newHeader.title,
          content: newHeader.content,
          link: newHeader.link,
          image: null,
          order: newHeader.order,
          view_id: newHeader.view.id,
          imagePreview: newHeader.image,
        };
      } else {
        isEditing.value = false;
        form.value.imagePreview = '';
      }
    }, { immediate: true });

    const updateFile = (type, file) => {
      form.value[type] = file;
    };

    const submitForm = async () => {
      const formData = new FormData();
      Object.keys(form.value).forEach((key) => {
        if (key !== 'imagePreview' && (key !== 'image' || form.value.image)) {
          formData.append(key, form.value[key]);
        }
      });
      const editOrCreate = isEditing.value ? "editar" : "crear";
      const confirmed = await userConfirmation(`¿Está seguro de ${editOrCreate} el carrousel?`);
      if (!confirmed) return;
      showLoadingAlert();
      try {
        if (isEditing.value) {
          await updateSlider(props.header.id, formData);
          emit('success', 'Slider actualizado exitosamente.');
        } else {
          await createSlider(formData);
          emit('success', 'Slider creado exitosamente.');
        }

        emit('slider-created');
        closeForm();
      } catch (error) {
        emit('error', error.response?.data?.message || 'Error al procesar la solicitud.');
       } finally {
        closeLoadingAlert();
      }
    };

    const closeForm = () => {
      emit('close');
      const modalElement = document.getElementById(SLIDER_FORM_MODAL_ID);
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance.hide();
    };

    onMounted(() => {
      const modalElement = document.getElementById(SLIDER_FORM_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      form,
      isEditing,
      updateFile,
      submitForm,
      closeForm,
    };
  },
};
</script>



<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.switch {
  position: relative;
  width: 145px;
  height: 37px;
  text-align: center;
  background: #4cd964;
  transition: all 0.2s ease;
  border-radius: 25px;
  margin-left: 1rem;
}

.switch span {
  position: absolute;
  width: 20px;
  height: 4px;
  top: 50%;
  left: 50%;
  margin: -2px 0 0 -4px;
  background: white;
  transform: rotate(-45deg);
  border-radius: 2px;
  transition: all 0.2s ease;
}

.switch span:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 12px;
  margin-top: -8px;
  background: white;
  border-radius: 2px;
}

input[type=radio] {
  display: none;
}

.switch label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.2);
  width: 58px;
  line-height: 37px;
  transition: all 0.2s ease;
}

label[for=yes] {
  position: absolute;
  left: 0;
}

label[for=no] {
  position: absolute;
  right: 0;
}

#no:checked ~ .switch {
  background: #ff3b30;
}

#no:checked ~ .switch span {
  background: white;
  margin-left: -8px;
}

#yes:checked ~ .switch label[for=yes],
#no:checked ~ .switch label[for=no] {
  color: white;
}

/* Estilos de transición */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
