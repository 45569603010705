<template>
  <div v-if="isOpen" class="modal fade show d-block" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ isEditMode ? 'Editar Provincia' : 'Nueva Provincia' }}</h5>
          <button type="button" class="close" @click="closeModal">&times;</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label>Nombre</label>
              <input type="text" v-model="formData.name" class="form-control" required />
            </div>
            <div class="form-group">
              <label>Código</label>
              <input type="text" v-model="formData.code" class="form-control" required />
            </div>
            <div class="form-group">
              <label>Estado</label>
              <select v-model="formData.status" class="form-control">
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </select>
            </div>
            <button type="submit" class="btn btn-primary">{{ isEditMode ? 'Actualizar' : 'Crear' }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { createProvince, updateProvince } from '@/services/api/provinceService.js';
import { showLoadingAlert, closeLoadingAlert } from "@/utils/loadingAlert.js";
import userConfirmation from "@/utils/userConfirmation.js";

export default {
  props: {
    provincia: {
      type: Object,
      default: null,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isEditMode = ref(!!props.provincia);
    const formData = ref({
      name: props.provincia?.name || '',
      code: props.provincia?.code || '',
      status: props.provincia?.status || 1,
    });

    watch(
      () => props.provincia,
      (newProvincia) => {
        if (newProvincia) {
          formData.value = {
            name: newProvincia.name || '',
            code: newProvincia.code || '',
            status: newProvincia.status || 1,
          };
          isEditMode.value = true;
        } else {
          formData.value = { name: '', code: '', status: 1 };
          isEditMode.value = false;
        }
      },
      { immediate: true }
    );

    const handleSubmit = async () => {
      const editOrCreate = isEditMode.value ? "editar" : "crear";
      const confirmed = await userConfirmation(
        `¿Está seguro de ${editOrCreate} la Provincia?`
      );
      if (!confirmed) return;
      showLoadingAlert();
      try {
        if (isEditMode.value) {
          await updateProvince(props.provincia.id, formData.value);
        } else {
          await createProvince(formData.value);
        }
        emit('success');
        closeModal();
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        closeLoadingAlert();
      }
    };

    const closeModal = () => {
      emit('close');
    };

    return {
      formData,
      isEditMode,
      handleSubmit,
      closeModal,
    };
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-dialog {
  max-width: 500px;
}
</style>
