import axios from '@/plugins/axios';

export const fetchPositions = async (params) => {
  try {
    const response = await axios.get('/positions', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching positions:', error);
    throw error;
  }
};

export const createPosition = async (positionData) => {
    const response = await axios.post('/positions', positionData);
    return response.data;
  };
  
  export const updatePosition = async (id, positionData) => {
    const response = await axios.put(`/positions/${id}`, positionData);
    return response.data;
  };

  export const deletePosition = async (id) => {
    const response = await axios.delete(`/positions/${id}`);
    return response.data;
  };