import axios from '@/plugins/axios';

export const fetchProvinces = async (params) => {
    try {
        const response = await axios.get('/provinces', {
            params
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching headers:', error);
        throw error;
    }
};

export const createProvince = async (provinceData) => {
  try {
      const response = await axios.post('/provinces', provinceData);
      return response.data;
  } catch (error) {
      console.error('Error creating province:', error);
      throw error;
  }
};
  
export const updateProvince = async (id, provinceData) => {
  try {
      const response = await axios.put(`/provinces/${id}`, provinceData);
      return response.data;
  } catch (error) {
      console.error('Error updating province:', error);
      throw error;
  }
};

export const deleteProvince = async (id) => {
  try {
      const response = await axios.delete(`/provinces/${id}`);
      return response.data;
  } catch (error) {
      console.error('Error deleting province:', error);
      throw error;
  }
};

// Obtener regionales asociados a una provincia
export const fetchProvinceRegionals = async (provinceId) => {
  try {
      const response = await axios.get(`/provinces/${provinceId}/regionals`);
      return response.data;
  } catch (error) {
      console.error('Error fetching province regionals:', error);
      throw error;
  }
};

// Obtener localidades asociadas a una provincia
export const fetchProvinceLocalities = async (provinceId) => {
  try {
      const response = await axios.get(`/provinces/${provinceId}/localities`);
      return response.data;
  } catch (error) {
      console.error('Error fetching province localities:', error);
      throw error;
  }
};

// Obtener detalles de una provincia específica
export const fetchProvinceDetails = async (provinceId) => {
  try {
      const response = await axios.get(`/provinces/${provinceId}`);
      return response.data;
  } catch (error) {
      console.error('Error fetching province details:', error);
      throw error;
  }
};

// Obtener localidades de todas las provincias
export const fetchAllLocalities = async () => {
  try {
      const response = await axios.get('/provinces/localities');
      return response.data;
  } catch (error) {
      console.error('Error fetching all localities:', error);
      throw error;
  }
};