import axios from '@/plugins/axios';

export const fetchButtonLinks = async (viewId, page = 1) => {
    try {
        const response = await axios.get('/button-links', {
            params: { viewId, page  }
        });
        return response.data.data;
    } catch (error) {
        console.error('Error fetching headers:', error);
        throw error;
    }
};

export const deleteButtonLink = async (headerId) => {
    try {
        await axios.delete(`/button-links/${headerId}`);
    } catch (error) {
        console.error('Error deleting header:', error);
        throw error;
    }
};

export const createButtonLink = async (formData) => {
    try {
        filterFormData(formData);

        const response = await axios.post('/button-links', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating buttonLink:', error);
        throw error;
    }
};

export const updateButtonLink = async (id, formData) => {
    try {
        formData.append('_method', 'PUT');
        
        filterFormData(formData);

        const response = await axios.post(`/button-links/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating buttonLink:', error);
        throw error;
    }
};

export const updateButtonLinkStatus = async (id, status, viewId) => {
    try {
        const response = await axios.put(`/button-links/${id}`, {
            status,
            view_id: viewId,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating buttonLink status:', error);
        throw error;
    }
};

export const fetchButtonLinksByTitle = async (viewId, title, type) => {
    try {
        const response = await axios.get('/button-links', {
            params: {
                viewId: viewId,
                title: title,
                type: type,
            }
        });
        return response.data.data;
    } catch (error) {
        console.error('Error fetching buttonLinks by title:', error);
        throw error;
    }
};


const filterFormData = (formData) => {
    for (let key of formData.keys()) {
        const value = formData.get(key);
        if (value === null || value === "null" || value === undefined || value === '') {
            formData.set(key, "");
        }
    }
};
