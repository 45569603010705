import axios from '@/plugins/axios';

export const fetchPricesApi = async ({ page, perPage, search, type }) => {
  const token = localStorage.getItem('user_access_token');
  return axios.get('/prices', {
    params: {
      page: page,
      per_page: perPage,
      search: search,
      vehiculeType: type,
      orderByMethod : "asc"
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


export const searchPrices = async ({ type, search, perPage = 15, orderBy = 'created_at', orderByMethod = 'asc', page = 1 }) => {
  const token = localStorage.getItem('user_access_token');
  
  return axios.get('/prices/search', {
    params: {
      vehiculeType: type,
      search,
      perPage,
      orderBy,
      orderByMethod,
      page,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

