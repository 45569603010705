<template>
  <div class="container p-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="mb-0" style="font-size: 2rem">Cargos</h1>
      <div class="d-flex align-items-center">
        <button @click="openModal(null)" class="btn btn-primary">Nuevo Cargo</button>
      </div>
    </div>

    <div class="mb-3">
      <input
        type="text"
        v-model="searchTerm"
        class="form-control"
        placeholder="Buscar..."
      />
    </div>

    <div v-if="loading" class="text-center">Cargando...</div>
    <div v-else>
      <table class="table table-striped table-bordered" style="width: 100%">
        <thead class="thead-dark">
          <tr>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Area</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="position in positions" :key="position.id">
            <td>{{ position.name }}</td>
            <td>{{ position.status === 1 ? 'Activo' : 'Inactivo' }}</td>
            <td>{{ position.area_name }}</td>
            <td class="text-center">
              <button
                @click="editCargo(position)"
                class="btn btn-sm btn-outline-primary mr-2"
              >
                Editar
              </button>
              <button
                @click="deleteCargoById(position.id)"
                class="btn btn-sm btn-outline-danger"
              >
                Eliminar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="lastPage > 1" class="d-flex justify-content-end">
        <button @click="fetchCargos(1)" class="btn btn-sm btn-outline-secondary mr-2" :disabled="currentPage === 1">
          Primera
        </button>
        <button @click="fetchCargos(currentPage - 1)" class="btn btn-sm btn-outline-secondary mr-2" :disabled="currentPage === 1">
          Anterior
        </button>
        <button @click="fetchCargos(currentPage + 1)" class="btn btn-sm btn-outline-secondary mr-2" :disabled="!hasMorePages">
          Siguiente
        </button>
        <button @click="fetchCargos(lastPage)" class="btn btn-sm btn-outline-secondary" :disabled="currentPage === lastPage">
          Última
        </button>
      </div>
    </div>

    <ModalFormPosition
      :isOpen="modalOpen"
      :position="selectedPosition"
      :areas="areas"
      @close="modalOpen = false"
      @success="handleModalSuccess"
    />
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { fetchPositions, deletePosition } from "@/services/api/positionService.js";
import { fetchAreasApi } from "@/services/api/areaService.js";
import ModalFormPosition from "@/components/position/ModalFormPosition.vue";
import userConfirmation from "@/utils/userConfirmation.js";

export default {
  components: {
    ModalFormPosition,
  },
  setup() {
    const positions = ref([]);
    const areas = ref([]);
    const loading = ref(false);
    const searchTerm = ref("");
    const orderBy = ref("name");
    const orderByMethod = ref("asc");
    const perPage = ref(10);
    const status = ref(null);
    const modalOpen = ref(false);
    const selectedPosition = ref(null);
    const currentPage = ref(1);
    const lastPage = ref(1);
    const hasMorePages = ref(false);

    const fetchAreas = async () => {
      try {
        const data = await fetchAreasApi();
        areas.value = data.data || [];
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    };

    const fetchCargos = async (page = 1) => {
      loading.value = true;
      try {
        const data = await fetchPositions({
          search: searchTerm.value,
          orderBy: orderBy.value,
          orderByMethod: orderByMethod.value,
          perPage: perPage.value,
          status: status.value,
          page,
        });
        positions.value = data.data || [];
        currentPage.value = data.meta.current_page;
        lastPage.value = data.meta.last_page;
        hasMorePages.value = data.meta.current_page < data.meta.last_page;
      } catch (error) {
        console.error("Error fetching positions:", error);
      } finally {
        loading.value = false;
      }
    };

    watch([searchTerm, orderBy, orderByMethod, perPage, status], () => {
      fetchCargos(1);
    });

    const openModal = (position) => {
      selectedPosition.value = position;
      modalOpen.value = true;
    };

    const handleModalSuccess = () => {
      modalOpen.value = false;
      fetchCargos(currentPage.value);
    };

    const editCargo = (position) => {
      openModal(position);
    };

    const deleteCargoById = async (id) => {
      const confirmed = await userConfirmation(
        `¿Está seguro de eliminar el cargo seleccionado?`
      );
      if (!confirmed) return;
      try {
        await deletePosition(id);
        fetchCargos(currentPage.value);
      } catch (error) {
        console.error("Error deleting position:", error);
      }
    };

    onMounted(() => {
      fetchCargos();
      fetchAreas();
    });

    return {
      areas,
      positions,
      loading,
      searchTerm,
      fetchCargos,
      modalOpen,
      selectedPosition,
      openModal,
      handleModalSuccess,
      editCargo,
      deleteCargoById,
      currentPage,
      lastPage,
      hasMorePages,
      orderBy,
      orderByMethod,
      perPage,
      status,
    };
  },
};
</script>

<style scoped>
</style>
