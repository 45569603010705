<template>
  <div
    class="modal fade"
    id="directorModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cardFormModalLabel">
            {{ isEditing ? "Editar Tarjeta" : "Nueva Tarjeta" }}
          </h5>
          <button type="button" class="btn-close" @click="hideModal"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row mb-3">
              <div class="col-md-6">
                <label for="name" class="form-label">Nombre</label>
                <input
                  type="text"
                  v-model="form.name"
                  class="form-control"
                  id="name"
                  required
                />
              </div>
              <div class="col-md-6">
                <label for="lastname" class="form-label">Apellido</label>
                <input
                  type="text"
                  v-model="form.lastname"
                  class="form-control"
                  id="lastname"
                  required
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-6">
                <label for="label" class="form-label">Prefijo</label>
                <input
                  type="text"
                  v-model="form.label"
                  class="form-control"
                  id="label"
                  required
                />
              </div>
              <div class="col-md-6">
                <label for="position" class="form-label">Cargo</label>
                <input
                  type="text"
                  v-model="form.position"
                  class="form-control"
                  id="position"
                  required
                />
              </div>
            </div>

            <FileUploadPreview
              v-if="subMenuIsOne"
              label="Imagen"
              id="image"
              accept="image/*"
              :file="form.image"
              :preview="form.image"
              :required="!isEditing"
              @file-change="updateFile('image', $event)"
              no-file-message="No se ha seleccionado ninguna imagen"
              helpId="image_card"
              @show-help="showHelp"
            />

            <button type="submit" class="btn btn-primary btn-custom">
              {{ isEditing ? "Actualizar" : "Guardar" }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, nextTick, computed } from "vue";
import { Modal } from "bootstrap";
import {
  createDirector,
  updateDirector,
} from "@/services/api/directorService.js";
import FileUploadPreview from "@/components/shared/FileUploadPreview.vue";
import userConfirmation from "@/utils/userConfirmation.js";
import { buildFormData } from "@/utils/formDataBuilder.js";

export default {
  components: {
    FileUploadPreview,
  },
  props: {
    subMenu: {
      type: Object,
      default: () => ({}),
    },
    director: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    
    const isEditing = ref(false);
    const form = ref({
      name: "",
      lastname: "",
      label: "",
      position: null,
      section: props.subMenu.value || null,
      image: null,
      order: "",
      view_id: "",
      imagePreview: "",
    });

    const modalInstance = ref(null);

    const subMenuIsOne = computed(() => props.subMenu.value === 1);

    const resetForm = () => {
      form.value = {
        name: "",
        lastname: "",
        label: "",
        position: null,
        section: props.subMenu.value || null,
        imagePreview: "",
        image: null,
        order: "",
        view_id: "",
      };
      isEditing.value = false;
    };

    const showModal = () => {
      if (modalInstance.value) modalInstance.value.show();
    };

    const hideModal = () => {
      if (modalInstance.value) modalInstance.value.hide();
      resetForm();
    };

    watch(
      () => props.subMenu.value,
      (newSubMenuValue) => {
        form.value.section = newSubMenuValue || null;
      }
    );

    watch(
      () => props.director,
      (newDirector) => {
        console.log("newDirector",newDirector)
        if (newDirector) {
          isEditing.value = true;
          form.value = {
            name: newDirector.name,
            lastname: newDirector.lastname,
            label: newDirector.label,
            position: newDirector.position,
            section: newDirector.section,
            image: newDirector.image || null,
            order: newDirector.order,
            imagePreview: newDirector.image || null,
            view_id: newDirector.view?.id || "",
          };
        } else {
            resetForm();
        }
      },
      { immediate: true }
    );

    

    const updateFile = (type, file) => {
      form.value[type] = file;
    };

    const submitForm = async () => {
      const formData = buildFormData(form.value);
      const action = isEditing.value ? updateDirector : createDirector;
      const message = isEditing.value ? "actualizar" : "crear";
      const confirmed = await userConfirmation(
        `¿Está seguro de ${message} la tarjeta?`
      );
      if (!confirmed) return;
      console.log("form.value",form.value)
       console.log("props.subMenu.value",props.subMenu.value)
      try {
        if (isEditing.value) {
          await action(props.director.id, formData);
        } else {
          await action(formData);
        }
        emit("import-completed");
        hideModal();
      } catch (error) {
        emit(
          "error",
          error.response?.data?.message || "Error al procesar la solicitud."
        );
      }
    };

    onMounted(async () => {
      await nextTick();
      const modalElement = document.getElementById("directorModal");
      if (modalElement) {
        modalInstance.value = new Modal(modalElement);
      }
    });

    return {
      form,
      isEditing,
      updateFile,
      submitForm,
      showModal,
      hideModal,
      subMenuIsOne,
    };
  },
};
</script>

<style scoped>
.btn-custom {
  margin-top: 15px;
}
</style>


<style scoped>
.preview-container {
  border: 1px solid #ccc;
  min-height: 100px;
  margin: 5px;
  background-color: #305785;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-color-input {
  width: 100%;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-custom {
  margin-top: 15px;
}
</style>
